
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'poppins';
    src: url('../../public/fonts/Poppins.ttf');
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: #11151A;
}

select {
    appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
#viewer {
    position: absolute;
    width: calc(100% - 106px);
    top: 64px;
    bottom: 0;
}
.adsk-viewing-viewer {
    z-index: 0 !important;
    bottom: 0% !important;
}

/* scrollbar style */
/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #212B36;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #454F5B;
    border-radius: 5px;
}
/* div arrow */
div.arrow::before,
div.arrow::after {
    content: '';
    position: absolute;
    top: -7px;
    right: 10px;
    margin: auto 0;
    width: 15px;
    height: 15px;
    background: #161C24;
    border-bottom: 1.2px solid #637381;
    border-right: 1.2px solid #637381;
    transform: rotate(-135deg);
    /*clip-path: polygon(100% 0, 0% 100%, 100% 100%);*/
    clip-path: polygon(100% 2%,
            2% 100%,
            100% 100%);
    /* firefox fix changed 0% to 2%*/
    border-radius: 0 0 4px 0;
}